<template>
<div class="apCodeUpload-page-content winner-page__hero">
   <div class="main-block position-relative">
      <div class="container-xl">
        <div class="row">
            <div class="col-12 px-0">
                <div class="winner-bg">
                    <div class="row position-relative">
                        <div class="col-12 col-xl-6 offset-xl-6 col-lg-7 offset-lg-4 d-flex justify-content-center flex-column position-relative" style="overflow-y:hidden;">
                            <img src="@/assets/imgs/brown-line.svg" class="position-absolute brown-line"/> 
                            <div class="bg-white position-relative polygon">
                                <h1 class="text-black text-uppercase text-center fs-28 mt-5">Gratulálunk!</h1>
                                <div class="text-center mt-5">
                                    <p class="mb-0 text-black fs-18">Érvényes pályázat esetén nyereményed</p>
                                    <span class="text-primary font-primary fs-24" v-if="winnerType == 'orankenti'">1 DB 5.000 Ft<br>pénznyeremény</span>
                                    <span class="text-primary font-primary fs-24" v-if="winnerType == 'heti_20'">1 DB 20.000 Ft<br>pénznyeremény</span>
                                    <span class="text-primary font-primary fs-24" v-if="winnerType == 'heti_50'">1 DB 50.000 Ft<br>pénznyeremény</span>
                                    <span class="text-primary font-primary fs-24" v-if="winnerType == 'heti_150'">1 DB 150.000 Ft<br>pénznyeremény</span>
                                    <span class="text-primary font-primary fs-24" v-if="winnerType == 'fodij'">1 DB 1.002.025 Ft<br>pénznyeremény</span>
                                    <p class="text-black fs-18">bankszámlára utalva</p>
                                    <div class="empty"></div>
                                    <img src="@/assets/imgs/18.svg" class="img-fluid d-block d-lg-none"/>                                   
                                </div>
                                
                            </div>
                        </div>
                        <img src="@/assets/imgs/18.svg" class="img-fluid eighteen d-lg-block d-none"/> 
                        <img src="@/assets/imgs/winner-hour.png" class="hero-product d-lg-block d-none" v-if="winnerType == 'orankenti'"/>  
                        <img src="@/assets/imgs/winner-hour-mobile.png" class="hero-product d-lg-none d-block" v-if="winnerType == 'orankenti'"/>
                        <img src="@/assets/imgs/winner_20.png" class="hero-product d-lg-block d-none" v-if="winnerType == 'heti_20'"/>  
                        <img src="@/assets/imgs/winner_20-mobile.png" class="hero-product d-lg-none d-block" v-if="winnerType == 'heti_20'"/>
                        <img src="@/assets/imgs/winner_50.png" class="hero-product d-lg-block d-none" v-if="winnerType == 'heti_50'"/>  
                        <img src="@/assets/imgs/winner_50-mobile.png" class="hero-product d-lg-none d-block" v-if="winnerType == 'heti_50'"/>
                        <img src="@/assets/imgs/winner_150.png" class="hero-product d-lg-block d-none" v-if="winnerType == 'heti_150'"/>  
                        <img src="@/assets/imgs/winner_150-mobile.png" class="hero-product d-lg-none d-block" v-if="winnerType == 'heti_150'"/>
                        <img src="@/assets/imgs/winner-main.png" class="hero-product d-lg-block d-none" v-if="winnerType == 'fodij'"/>  
                        <img src="@/assets/imgs/winner-main-mobile.png" class="hero-product d-lg-none d-block" v-if="winnerType == 'fodij'"/>                 
                    </div>  
                </div>
            </div>
        </div>   
    </div> 
     <div class="text-center py-2" style="background-color: #333333;">
        <p class="hero__info-text py-5 mb-0 text-white">A vásárlást igazoló eredeti bizonylatot a nyereményjáték lezárulta után további 1 hónapig <b>(2025. február 2-ig)</b>, illetve nyertesség esetén a nyeremények átadásáig meg kell őrizni. A képek csak illusztrációk.</p>
    </div>   
  </div>
   <!-- <div class="bg-primary">
        <div class="col-12 col-lg-8 mx-auto text-center fs-14 fs-md-18 text-white py-5 d-flex flex-wrap justify-content-center align-items-center ">                                
            <p class="mb-0">A vásárlást igazoló eredeti bizonylatot sértetlen, ép állapotban <b>2024. szeptember 28-ig</b> meg kell őrizni. <br>A képek csak illusztrációk.</p>
        </div>    
    </div>
  <Ribbon/>-->
</div>
</template>

<script>
//import Ribbon from '@/components/Ribbon.vue'

export default {
    components:{
        //Ribbon
    },
    props:['winnerType']
}
</script>