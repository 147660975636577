<template>
  <div class="winner-page-main" id="winnerFormWrap">    
    <FormExpiredAndRegistered
        v-if="formExpired || alreadyRegistered || formDisabled || uploadedWinner === true"
        :formExpired="formExpired"
        :alreadyRegistered="alreadyRegistered"
        :formDisabled="formDisabled"
        :winnerType="winnerType"
    />

    <div v-else class="winner-page__main-content">
      <CodeFillingWinnerformHero :winnerType="winnerType" :needBtn="true" />
      
      <div
          class="winner-page__codefilling-form-background second-background-inner block--big"
          :class="{ 'congrat-background-space': uploadedWinner === true }"
      >
        <h2 class="text-center fw-700 text-black pb-5 px-2">Kérjük, add meg adataid!</h2>        
        
          <div class="container-xl">
            <div class="row">
              <div class="col-md-12 col-lg-10 offset-lg-1">              
                <div class="codefilling-form" style="border-radius:20px">                
                    <validation-observer
                        v-if="formStatus === false"
                        v-slot="{ handleSubmit }"
                        tag="div"
                        ref="winnerFormObserver"
                    >
                      <form
                          class="form form--codefilling"
                          @submit.prevent="handleSubmit(formSubmit)"
                          id="winnerForm"
                      >
                        <div class="row">
                          <div class="col-12">
                            <div class="winner-codefilling__form">             
                              <div class="form-group row">
                                <div class="surname-row input--info col-12 col-md-6">                               
                                  <CustomInput
                                    label="Vezetéknév"
                                    name="last_name"                                                                        
                                    v-model="form.last_name"
                                    disabled
                                  />  
                                </div>
                                <div class="forname-row input--info col-12 col-md-6">                      
                                   <CustomInput
                                    label="Keresztnév"
                                    name="last_name"                                                                        
                                    v-model="form.first_name"
                                    disabled
                                  />  
                                </div>
                                <div class="form-row col-12 col-md-6">
                                    <CustomInput
                                        label="E-mail cím"
                                        name="email"
                                        v-model="form.email"
                                        disabled                     
                                    />
                                </div>
                              
                              <div class="form-group mb-0 form-row col-12 col-md-6">
                                 
                                  <div class="form-row phone-wrap phone-row">
                                    <div class="form-label">Telefonszám<span class="label-red"> * </span></div>
                                    <div class="d-flex w-100 align-items-start position-relative">
                                        <span class="me-2 d-block phone-prefix">+36</span>
                                        <CustomInput
                                            class="w-100"
                                            label=""
                                            name="phone"                                                                                        
                                            tooltip="Egy telefonszám, amelyen a játékos elérhető."
                                            v-model="phoneShort"                                        
                                            @input="formatPhoneNumber($event)"                                                                                
                                            rules="required|customPhone"
                                            :disabled="is_phone_number_already"
                                        />
                                    </div>
                                </div>
                            
                               
                              </div>
                                  <div
                                      class=" form-group zip-row input--info col-12 col-md-6">
                                      <!-- zip  -->                                 
                                    <div class="col-12">
                                      <CustomInput
                                          label="Irányítószám"
                                          type="text"
                                          name="zip"
                                          placeholder="Irányítószám"                                          
                                          v-model="form.zip"
                                          rules="required|min:4"
                                          :disabled="is_step2_ever_done"
                                          v-mask="['####']"
                                      />
                                    </div>
                                  </div>
                                  <div class="form-group input--info city-row col-12 col-md-6">                                 
                                      <div class="col-12">
                                        <CustomInput
                                            label="Település"
                                            type="text"
                                            name="city"
                                            placeholder="Település"                                          
                                            v-model="form.city"
                                            rules="required"
                                            :disabled="is_step2_ever_done"
                                        />
                                      </div>
                                </div>
                                  <div class="input--info street-row col-12 col-md-6">
                                        <CustomInput
                                            label="Utca"
                                            type="text"
                                            name="street"
                                            placeholder="Utca"                                          
                                            v-model="form.street"
                                            rules="required"
                                            :disabled="is_step2_ever_done"
                                        />
                                  </div>
                                  <div class="input--info house-row col-12 col-md-6">
                                    <CustomInput
                                            label="Házszám, emelet, ajtó"
                                            type="text"
                                            name="house"
                                            placeholder="Házszám, emelet, ajtó"                                          
                                            v-model="form.house"
                                            rules="required"
                                            :disabled="is_step2_ever_done"
                                        />
                                  </div>                                                                                 
                            </div>
                            <div
                                class=" form-group zip-row input--info col-12 col-md-6">
                                <!-- bankszámla  -->                                 
                              <div class="col-12">
                                <CustomInput
                                    label="Bankszámlaszám"
                                    type="text"
                                    name="bank_account_number"
                                    placeholder="Bankszámlaszám"                                          
                                    v-model="form.bank_account_number"
                                    rules="required|min:16"
                                    v-mask="['########-########', '########-########-########']"
                                />
                              </div>
                            </div>

                            <!-- BLOKK FELTÖLTÉS -->
                            <!-- v-if="needFileUpload" -->
                            <FileUpload v-if="winnerType == 'heti'" :imageApiError="imageApiError" @changeFile="changeFile" :imageError="imageError" :forBank="true" :msg="form.buy_type == 'shop' ? 'Max 5 MB - png vagy jpeg formátum': 'Max 5 MB - png, pdf vagy jpeg formátum'"
                                 class="mt-5"/>
                            
                            <div style="width:100%;border-top:1px solid #005FA3;" class="my-5"></div>
                            
                            <div class="mt-3 mb-4 text-start d-flex">                                
                                <span class="text-red fw-bold">*</span>
                                <span>&nbsp;</span> 
                                <span><p class="m-0 font-secondary" style="font-size:14px">A csillaggal jelölt mezők kitöltése szükséges.</p></span>
                            </div>
                          <!-- info text -->
                            <div class="info-block d-flex align-items-center p-4 mb-7" style="border-radius:8px;background:#FEFBCD;">                      
                                
                                <img src="@/assets/imgs/exclamation-circle.svg" alt="mark" class="img-fluid me-2" /> 
                                <p class="m-0 text-black fs-14">A vásárlást igazoló eredeti blokkot <b>2025. február 2-ig</b> őrizd meg!</p>
                            </div>

                              <!-- SUBMIT button                               -->
                              <div class="d-flex justify-content-center mt-4">
                                    <button type="submit" class="btn btn-primary mr-0 btn-primary--large">
                                      <span v-if="is_step2_ever_done" >Adatok megerősítése</span>
                                      <span v-else>Adatok elküldése</span>      
                                    </button>
                              </div>
                            </div>
                            <div>

                            </div>
                          </div>
                        </div>

                
                      </form>
                    </validation-observer>                
                </div>
              </div>
            </div>
          </div>        
      </div>      
    </div>    
  </div>
</template>

<script>
import {mask} from "vue-the-mask";
// import $ from "jquery";
/* eslint-disable */
import CodeFillingWinnerformHero from '@/components/CodeFillingWinnerFormHero.vue'
import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import {required, email} from "vee-validate/dist/rules";
import {setInteractionMode} from "vee-validate";
import FormExpiredAndRegistered from "@/components/FormExpiredAndRegistered";
import CustomInput from '@/components/base/CustomInput.vue'
import FileUpload from '@/components/FileUpload.vue'

setInteractionMode("eager");
// Add a validation rules

extend("email", {
  ...email  
});
extend("required", {
  ...required  
});
extend("min", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"]  
});


export default {
  data() {
    return {
      needFileUpload:false,
      form: {
        last_name: "",
        first_name: "",
        email: "",
        zip: "",
        city: "",
        phone: "",
        street: "",
        house: "",
        hash: this.$route.query.hash,
        hash2: this.$route.query.hash2,
        bank_account_number: "",
        receipt: null,
        receipt2: null,
        receipt3: null,
        receipt4: null,
        receipt5: null,
        buy_type: 'shop'        
      },      
      phoneShort: '',
      phoneRaw:'',
      maxFileUploadNum:5,
      formStatus: false,
      alreadyRegistered: false,
      responseErrors: null,
      triedFillForm: false,
      uploadedWinner: false,
      winnerType: null,
      uploadedError: false,
      imageError: false,
      uploadedFiles: [],
      formExpired: false,
      formDisabled: false,
      formIsSubmitting: false,
      imageApiError:null,
      is_phone_number_already:false,
      is_step2_ever_done: false,
    };
  },
  components: {
    FormExpiredAndRegistered,
    ValidationProvider,
    ValidationObserver,
    CodeFillingWinnerformHero,
    CustomInput,
    FileUpload
  },
  directives: {
    mask,
  },

  methods: {
    backToForm() {
      this.$router.push("/");
      window.scrollTo(0, 0);
    },   
     handleFilesUpload() {
       let _this = this;
       //remove the error msg if there was
       this.$refs.uploadederror.innerHTML = "";

       let uploadedItem = this.$refs.receipt.files[0];

      //check file size 5Mb max
       let maxFileSize = 1024 * 1024 * 5;
       let fileSize = uploadedItem.size;

       //check file fomat
       // let isGoodExtension = this.checkFileFormat(uploadedItem.name);

       //check file size - max 2Mb
       if (fileSize > maxFileSize) {
         this.$refs.uploadederror.innerHTML = _this.tr('file-upload-big-size')
             // "Túl nagy a fájl mérete (max. 5Mb)";
         // } else if (isGoodExtension == false) {
         //   this.$refs.uploadederror.innerHTML = "Nem megfelelő formátum.";
       } else {
         if (_this.uploadedFiles.length < parseInt(_this.maxFileUploadNum)) {
           //max 5 item could be uploaded

           let nameIndex = _this.uploadedFiles.find(
               (item) => item.name == uploadedItem.name
           );

           if (nameIndex == undefined) {
             _this.uploadedFiles.push(uploadedItem);
           } else {
             this.$refs.uploadederror.innerHTML = "Megegyező fájl név."
           }
         } else {
           _this.$refs.uploadederror.innerHTML = "Nem tölthető fel több fájl."
         }
       }

       this.fillReceipts();

       //set the default state for the input type=file
       this.$refs.receipt.value = ""

       if (this.form.receipt != null) {
         this.imageError = false;
       }
     },
     fillReceipts() {
       let _this = this;

       _this.form.receipt = null;
       _this.form.receipt2 = null;
        _this.form.receipt3 = null;
        _this.form.receipt4 = null;
        _this.form.receipt5 = null;

       this.uploadedFiles.forEach(function (item, i) {
         if (i == 0) {
           _this.form.receipt = item;
         } else if (i == 1) {
           _this.form.receipt2 = item;
         } else if (i == 2) {
            _this.form.receipt3 = item;
          } else if (i == 3) {
            _this.form.receipt4 = item;
          } else if (i == 4) {
            _this.form.receipt5 = item;
          }
       });
     },
     delUploadedFile(delItem) {
       let _this = this
       let delItemIndex = this.uploadedFiles.indexOf(delItem);

       if (delItemIndex != -1) {
         _this.uploadedFiles.splice(delItemIndex, 1);
       }

       this.$refs.uploadederror.innerHTML = "";
       this.fillReceipts();
     },
      changeFile( form){
            console.log("changeFile", form)
            this.form.receipt = form.receipt
            this.form.receipt2 = form.receipt2
            this.form.receipt3 = form.receipt3
            this.form.receipt4 = form.receipt4
            this.form.receipt5 = form.receipt5
        },
    getFormData(){
          let _this = this
          let formData = new FormData();
                     
          _this.form.phone = '+36-' + this.phoneShort
          
           // formData.append("bank_account_number_image", _this.form.receipt);
          formData.append("bank_account_number", _this.form.bank_account_number);

          formData.append("phone", _this.form.phone);
          formData.append("zip", _this.form.zip);
          formData.append("city", _this.form.city);
          formData.append("street", _this.form.street);
          formData.append("house", _this.form.house);
          formData.append("hash", _this.form.hash);
          formData.append("hash2", _this.form.hash2);
          formData.append("receipt", _this.form.receipt);
          formData.append("receipt2", _this.form.receipt2);
          formData.append("receipt3", _this.form.receipt3);
          formData.append("receipt4", _this.form.receipt4); 
          formData.append("receipt5", _this.form.receipt5);         

          return formData
    },
    formSubmit() {
      var _this = this;      
      
      //if the form needs file upload
      // if (this.needFileUpload){
        if (this.winnerType == 'heti'){
          this.imageApiError = false

          //kötelező blokkot feltölteni
          //console.log(_this.form.receipt);
          if (!_this.form.receipt) {
            _this.imageError = true;
            return;
          }
        }  

      if (!_this.formIsSubmitting) {
        _this.formIsSubmitting = true      

        let formContent = null
        formContent = _this.getFormData()

        // if (this.needFileUpload){
        //   formContent = _this.getFormData()
        // }else if(this.winnerType == 'napi'){
        //   formContent = _this.getFormData()
        // }else {
        //   _this.form.phone = '+36-' + this.phoneShort
        //   formContent = _this.form
        // }

        this.post("step2/upload", formContent, {})
            .then((response) => {
              var _this = this;              
              console.log(_this.response);
              if (!response.data.status || response.data.status === false) {                                
                console.log("response false")
                _this.goToError(error) 

              } else if (response.data.status) {
                console.log('success post')
                //success
                _this.formStatus = true;
                _this.uploadedWinner = true;
                // scroll to success screen's top
              
                  let element = document.querySelector('#success-data-upload')
                  if (element){
                      _this.scrollToElement(element, 20)       
                  }
              }

              _this.formIsSubmitting = false
              
            }).catch(function (error) {
              console.log('catch post')
              console.log(error);
              _this.formIsSubmitting = false

              let errorsArray = Object.entries(error.response.data.error)
              for (const [errorName, errorValue] of errorsArray) {
                  if (errorName === "receipt" || errorName === "receipt2" || errorName === "receipt3" || errorName === "receipt4" || errorName === "receipt5"){
                      _this.imageApiError = true
                      
                  } else {
                        
                        // GTMcategory = 'egyeb_hiba'                                                        
                  }
              }

              _this.goToError(error)                        

            });
      } else {
        console.log('double form submit')
      }


    }, 
    goToError(err){            
        let _this = this
        //scroll to the first error
        this.setErrorMsgs(err, 'winnerFormObserver').then(()=>{                               
          let firstError = document.querySelector('#winnerForm .error')
          if (firstError){
              _this.scrollToElement(firstError, 20)       
          }          
        })                                       
    },
  },  
  created() {
    let _this = this

    // for safety reasons - logged out the user   
    //TODO: csak akkor léptessük ki ha nem egyezik meg a user  
    localStorage.setItem("token", false);    

    this.get(            
            "step2/hash?hash=" +
            this.$route.query.hash +
            "&hash2=" +
            this.$route.query.hash2
        )
        .then((response) => {
          // console.log(response.data)

          if (response.data.status === false) {
            //not winner or not good hash
            console.log('error')
            this.$router.push({name: "APCodeUpload"});
          } else if (response.data.status.toLowerCase() == "ok") {
            //a winner opened the page
            if (!response.data.winner) {
              // console.log("nem nyertes")
              this.$router.push({name: "APCodeUpload"});
            } else {
              
              if (parseInt(response.data.winner.step2_done) === 1 ) {
            
                _this.formStatus = true;
                _this.alreadyRegistered = true;
              }

              if(parseInt(response.data.winner.is_step2_ever_done) === 1 ) {
                _this.is_step2_ever_done = true;
              }
              
              _this.form.buy_type = response.data.winner.buy_type;
              _this.winnerType = response.data.winner.type;
              _this.form.email = response.data.winner.email;
              _this.form.first_name = response.data.winner.first_name;
              _this.form.last_name = response.data.winner.last_name;
              //user data
              _this.form.street = response.data.winner.street
              _this.form.house = response.data.winner.house
              if (response.data.winner.phone){
                  _this.is_phone_number_already = true //for disabled the phone number
                  _this.form.phone = response.data.winner.phone.slice(4)
                  _this.phoneShort = response.data.winner.phone.slice(4)
              }
              
              _this.form.city = response.data.winner.city
              _this.form.zip = response.data.winner.zip
            }
          } else if (response.data.status.toLowerCase() == "expired" && parseInt(response.data.winner.step2_done) === 0) {
            //the time is over than 5 days
            this.formExpired = true;
            _this.winnerType = response.data.winner.type;
          } else if (response.data.status.toLowerCase() == "expired" && parseInt(response.data.winner.step2_done) === 1) {
            //the time is over than 5 days, but sent data
            this.alreadyRegistered = true;
            _this.winnerType = response.data.winner.type;
          } else if (response.data.status.toLowerCase() == "disabled") {
            // console.log("status disabled");
            this.formDisabled = true;
            _this.winnerType = response.data.winner.type;
          }
        }).catch(function(){       
          //the url isn't valid  
          _this.$router.push({name: "APCodeUpload"});
        })
  } 
};
</script>
